import AEM from 'common-ui-frontend/src/main/webpack/base/js/aem';

class supportArticle extends AEM.Component {
    init() {
        this.removeClasses = [ 'col-md-8', 'col-lg-9' ];
        this.sideNav = this.element.querySelector('.side-nav');
        this.centerContainer = this.element.querySelector('.center-container');
        this.sizeColumns();
    }

    sizeColumns() {
        const granite = 'Granite' in window;
        if (!granite && this.sideNav && !this.sideNav.querySelector('.navbar-anchor-bar')) {
            this.sideNav.remove();
            this.centerContainer.classList.remove(...this.removeClasses);
        }
    }
}

export { supportArticle };
