import AEM from 'common-ui-frontend/src/main/webpack/base/js/aem';

class pressComponents extends AEM.Component {
    init() {
        const shareContainer = document.getElementById('sharethis-container');
        const shareTitle = shareContainer.querySelector('.sharethis-title');
        const shareContent = shareContainer.querySelector('.sharethis-inline-share-buttons');
        const article = document.querySelector('.article-container');
        const media = document.querySelector('.media-container .sticky-top');
        const mediaQuery = window.matchMedia('(max-width: 768px)');

        /* Hide label if ShareThis isn't loaded */
        const domObserver = new MutationObserver((_mutationList, observer) => {
            const shareButtons = shareContent.querySelector('.st-btn.st-last');
		  	if (shareButtons) {
		    	shareTitle.style.display = 'block';
		    	observer.disconnect();
		  	}
        });

        domObserver.observe(document.body, { childList: true, subtree: true });

        /* Move ShareThis to top in mobile */
        function moveSocial(mq) {
            if (mq.matches) {
                article.prepend(shareContainer);
            } else {
                media.prepend(shareContainer);
            }
        }

        moveSocial(mediaQuery);
        mediaQuery.addEventListener('change', moveSocial);
    }
}

export { pressComponents };
